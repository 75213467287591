exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-location-tsx": () => import("./../../../src/pages/about/location.tsx" /* webpackChunkName: "component---src-pages-about-location-tsx" */),
  "component---src-pages-about-profile-tsx": () => import("./../../../src/pages/about/profile.tsx" /* webpackChunkName: "component---src-pages-about-profile-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-digitization-digiscan-tsx": () => import("./../../../src/pages/digitization/digiscan.tsx" /* webpackChunkName: "component---src-pages-digitization-digiscan-tsx" */),
  "component---src-pages-digitization-p-2-o-tsx": () => import("./../../../src/pages/digitization/p2o.tsx" /* webpackChunkName: "component---src-pages-digitization-p-2-o-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-software-custom-tsx": () => import("./../../../src/pages/software/custom.tsx" /* webpackChunkName: "component---src-pages-software-custom-tsx" */),
  "component---src-pages-software-kisad-sport-tsx": () => import("./../../../src/pages/software/kisad-sport.tsx" /* webpackChunkName: "component---src-pages-software-kisad-sport-tsx" */),
  "component---src-pages-software-resq-team-tsx": () => import("./../../../src/pages/software/resq-team.tsx" /* webpackChunkName: "component---src-pages-software-resq-team-tsx" */),
  "component---src-pages-sport-kisad-sport-tsx": () => import("./../../../src/pages/sport/kisad-sport.tsx" /* webpackChunkName: "component---src-pages-sport-kisad-sport-tsx" */),
  "component---src-pages-sport-print-tsx": () => import("./../../../src/pages/sport/print.tsx" /* webpackChunkName: "component---src-pages-sport-print-tsx" */),
  "component---src-pages-sport-results-tsx": () => import("./../../../src/pages/sport/results.tsx" /* webpackChunkName: "component---src-pages-sport-results-tsx" */),
  "component---src-pages-sport-tsx": () => import("./../../../src/pages/sport.tsx" /* webpackChunkName: "component---src-pages-sport-tsx" */)
}

